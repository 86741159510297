<template>
  <b-table-simple
    :id="id"
    :ref="id"
    :striped="striped"
    responsive
    hover
    sticky-header="60vh"
    class="m-0 common-table"
    small
  > 
    <b-thead>
      <b-tr class="upper-header" id="upper-header">
        <b-th 
          v-for="(field, index) in fields" :key="index"
          class="pointer"
          :class="field.thClass" :style="field.thStyle"  
          align-v="center"
        >
          <slot :name="`header(${field.key})`" :field="field">
            <b-form-checkbox  v-if="field.key=='select'" class="m-0" @change="selectAll"/>
            <span
              v-else-if="field.key=='filter'" 
              @click="showFilter = !showFilter"
              style="padding: 0px 9px"
            >
              <font-awesome-icon v-if="!showFilter" class="btn-icon" :icon="['fas', 'caret-left']" size='lg'/>
              <font-awesome-icon v-else class="btn-icon" :icon="['fas', 'caret-down']" size='lg'/>
            </span>
            <div v-else @click="sortChange(field.key)">
              <span v-html="getFieldLabel(field)"/>
              <span v-if="isSorting(field.key)" class="ml-1">
                <font-awesome-icon v-if="sortBy.direction == 'DESC'" :icon="['fas', 'arrow-down']"/>
                <font-awesome-icon v-else :icon="['fas', 'arrow-up']"/>
              </span>
            </div>
          </slot>
        </b-th>
      </b-tr>
      <CommonTableFilter
        :fields="fields"
        :showFilter="showFilter"
        @filter="reloadFilter($event)"
      />
    </b-thead>
    <b-tbody v-if="showLoading">
      <b-td :colspan="fields.length" :rowspan="10" class="text-center py-5 border-none">
        <b-spinner variant="primary"/>
      </b-td>
    </b-tbody>
    <b-tbody v-else-if="items == null || items.length == 0">
      <b-td :colspan="fields.length" class="text-center py-5 border-none">
        There is no data to show
      </b-td>
    </b-tbody>
    <b-tbody v-else class="position-relative" :style="{ transform: `translateY(${showFilter ? 0 : '-42px'})` }">
      <b-tr v-for="(item, index) in items" :key="index" >
        <b-td 
          :class="field.tdClass" 
          :style="field.tdStyle" 
          v-for="(field, id) in fields" 
          :key="id" @click="onClickField(item, field)"
        >
          <slot :name="`cell(${field.key})`" :item="item" :index="index" :field="field" :value="item[field.key]">
            <div 
              v-if="field.key=='select'"
            >
              <b-form-checkbox
                v-model="selectedData"
                :value="item.id"
              />
            </div>
            <div
              v-else
              v-html="getFormattedData(item, field)"
            >
              <font-awesome-icon 
                v-if="field.hasIcon" 
                :class="field.iconClass"
                :style="field.iconStyle"
                :icon="field.hasIcon" 
                size="lg"
              />
            </div>
            <b-button
              v-if="field.hasIcon" 
              variant="flat-dark"
              class="rounded-circle" style="padding: 4px 10px"
            >
              <font-awesome-icon 
                :icon="field.hasIcon" 
                :size="field.iconSize || 'lg'"
              />
            </b-button>
          </slot>
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tfoot v-if="!showLoading && items && items.length > 0 && hasFooter" class="footerClass">
      <b-th :class="field.thClass" :style="field.thStyle" v-for="(field, index) in fields" :key="index" >
        <slot :name="`footer(${field.key})`" :field="field">
          <div v-if="field.footer">
            {{field.footer(items)}}
          </div>
        </slot>
      </b-th>
    </b-tfoot>
  </b-table-simple>
</template>

<script>
import { BTable, BSpinner } from "bootstrap-vue";
import { capitalize, title } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import CommonTableFilter from "./CommonTableFilter"


export default {
  components: {
    BTable, BSpinner,
    flatPickr,
    vSelect,
    CommonTableFilter
  },

  props: {
    id: {
      type: String,
      default: "common-table"
    },
    items: Array,
    fields: Array,
    perPage: Number,
    currentPage: Number,
    showLoading: Boolean,
    selected: Array,
    striped: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedData: this.selected,
      showFilter: false,
      afterShowFilter: false,
      sortBy: {key: "", direction: "DESC"},
      filterBy: null,
    };
  },

  computed: {
    fieldsMap() {
      return this.fields.reduce((o, field) => Object.assign(o, {[field?.key]: field}), {});
    },
    headerHeight() {
      // console.log("header height:", document.querySelector("#upper-header"))
      return this.afterShowFilter ? `${document.querySelector("#upper-header")?.offsetHeight ?? 0}px` : '0px'
    }
  },

  methods: {
    isSorting(key) {
      return this.sortBy.key == (this.fieldsMap[key].sort_key ?? key)
    },
    selectAll(checked) {
      this.$emit("selectedChange", checked ? this.items.map((c) => c.id).slice() : [])
    },
    getFieldLabel(field) {
      return field?.label ?? title(field.key, '_')
    },
    getFormattedData(item, field) {
      return field.formatter?.(item[field.key], field.key, item) ?? item?.[field.key]
    },

    reloadFilter(filterBy) {
      this.filterBy = filterBy;
      this.$emit("fetchItems", this.perPage, this.currentPage, this.parseSort(this.sortBy), filterBy)
    },

    reloadTable() {
      this.$emit("fetchItems", this.perPage, this.currentPage, this.parseSort(this.sortBy), this.filterBy)
    },
  
    async sortChange(key) {
      if (!this.fieldsMap[key]?.sortable) return;
      key = this.fieldsMap[key].sort_key ?? key
      if (this.sortBy.key != key) {
        this.sortBy.key = key
        this.sortBy.direction = "DESC"
      }
      else {
        this.sortBy.direction = this.sortBy.direction == "DESC" ? "ASC" : "DESC"
      }
      await this.$nextTick()
      this.reloadTable()
    },

    parseSort(sort_by) {
      return sort_by.key ? sort_by : undefined 
    },

    onClickField(item, field) {
      if (field.onclick) {
        field.onclick(item)
      } else {
        this.$emit("onclick", item, field)
      }
    }
  },

  mounted() {
    this.$emit("setCurrentPage", 1)
  },

  watch: {
    async showFilter() {
      await this.$nextTick()
      this.afterShowFilter = this.showFilter
    },
    async perPage(newVal, oldVal) {
      await this.$nextTick()
      this.reloadTable()
    },
    async currentPage(newVal, oldVal) {
      await this.$nextTick()
      this.reloadTable()
    },
    selectedData() {
      this.$emit("selectedChange", this.selectedData)
    },
    selected() {
      this.selectedData = this.selected
    },
  }
};
</script>

<style scoped lang="scss">
.upper-header {
  overflow: scroll;
}
.footerClass {
  /* background-color: var(--light); */
  th:first-child {
    border-bottom-left-radius: 10px;
  }
  th:last-child {
    border-bottom-right-radius: 10px;
  }
}
</style>

<style>
.common-table thead th {
  vertical-align: middle !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>