<template>
  <!-- show filter regardless of 'showFilter', only hide when loading == false && showFilter == false -->
  <!-- <b-tr id="lower-header" :class="{ 'hide-header': !showFilter }"> 
    <template v-for="(field, index) in fields" class="lower-header">
      <b-th :key="index"  style="vertical-align:middle" :class="field.thClass" :style="field.thStyle">
        <div class="pointer" v-if="field.key=='filter'" @click="clearFilter">
          Clear
        </div>
        <div v-else-if="field.filter && field.filter.type=='none'"/>
        <div v-else-if="field.filter && field.filter.type=='select'"> 
          <b-form-select v-model="filterBy[field.key].filter" size="sm" @change="filterChange" :options="field.filter.options" :clearable="false" />
        </div>
        <div v-else-if="field.filter && field.filter.type=='date'"> 
          <flat-pickr
            v-model="filterBy[field.key].filter"
            class="form-control form-control-sm"
            :config="{ mode: 'range' }"
            @on-close="filterChange"
          />
        </div>
        <div :key="index" v-else>
          <b-form-input v-model="filterBy[field.key].filter" @change="filterChange" size="sm"/>
        </div>
      </b-th>
    </template>
  </b-tr> -->
  <b-tr v-if="filterBy" class="lower-header" id="lower-header" :class="{ 'hide-header': !showFilter }" :style="{'--header-height': headerHeight}">
    <template v-for="(field, index) in fields" class="lower-header">
      <b-th :key="index" class="py-50" style="vertical-align:middle" :class="field.filterClass"  :style="field.filterStyle">
        <slot :name="`filter(${field.key})`" :field="field">
          <div class="pointer" v-if="field.key=='filter'" @click="clearFilter">
            Clear
          </div>
          <div v-else-if="field.filter && field.filter.type=='none'"/>
          <div v-else-if="field.filter && field.filter.type=='select'"> 
            <b-form-select
              v-if="field.filter.selectType == 'multiple'"
              multiple
              v-model="filterBy[field.key].filter" 
              @change="filterChange" 
              :options="field.filter.options" 
              :clearable="false"
              class="w-100 form-control-table-filter"
              size="sm"
            />
            <b-form-select
              v-else
              v-model="filterBy[field.key].filter" 
              @change="filterChange" 
              :options="field.filter.options" 
              :clearable="false"
              class="w-100 form-control-table-filter"
              size="sm"
            />
          </div>
          <div v-else-if="field.filter && field.filter.type=='date'"> 
            <flat-pickr
              v-model="filterBy[field.key].filter"
              class="form-control form-control-sm form-control-table-filter"
              :config="{ mode: 'range' }"
              @on-close="filterChange"
            />
          </div>
          <div :key="index" v-else>
            <b-form-input 
              v-model="filterBy[field.key].filter" 
              @change="filterChange"
              size="sm" 
              :style="{
                maxWidth: field.filterMaxWidth ? field.filterMaxWidth : 'initial'
              }"
              class="form-control-table-filter"
              placeholder="All"
            />
          </div>
        </slot>
      </b-th>
    </template>
  </b-tr>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from "vue-flatpickr-component"
import moment from "moment"

export default {
  components: {
    flatPickr,
    vSelect
  },

  props: {
    showFilter: Boolean,
    fields: Array,
  },

  data() {
    return {
      filterBy: this.filterPrototype(),
      afterShowFilter: false,
    }
  },

  computed: {
    fieldsMap() {
      return this.fields.reduce((o, field) => Object.assign(o, {[field?.key]: field}), {});
    },

    headerHeight() {
      let el = document.querySelector("#lower-header")?.parentElement?.querySelector?.("tr")
      return this.afterShowFilter ? `${el?.offsetHeight ?? 0}px` : '0px'
    }
  },

  methods: {
    filterPrototype() {
      return JSON.parse(JSON.stringify(this.fields.reduce((o, field) => Object.assign(o, field == null ? {} : {[field?.filter?.key ?? field?.key]: field?.filter?.default ?? {filter: (field?.filter?.type == 'select' ? 'All': ''), type: "equals"}}), {})))
    },

    reloadTable() {
      this.$emit("filter", this.parseFilter(this.filterBy))
    },

    async clearFilter() {
      this.filterBy = this.filterPrototype()
      await this.$nextTick()
      this.reloadTable()
    },

    async filterChange(value) {
      await this.$nextTick()
      this.reloadTable()
    },

    parseFilter(filter_by) {
      let filterBy = {}
      Object.keys(filter_by).forEach((key) => {
        if (this.fieldsMap[key].filter?.type == 'date') {
          if (filter_by[key].filter) {
            let dates = filter_by[key].filter.split(" ")
            filterBy[key] = {
              ...filter_by[key],
              dateFrom: moment(dates[0]).format(),
              dateTo: moment(dates[dates.length - 1]).add(1, 'days').format()
            }
          }
        }
        else if (this.fieldsMap[key].filter?.type == 'select') {
          if (!['All', 'all'].includes(filter_by[key]?.filter)) {
            filterBy[key] = filter_by[key]
          }
        } 
        else {
          if (filter_by[key]?.filter) {
            filterBy[key] = filter_by[key]
          }
        }
      })
      // console.log(filterBy)
      return filterBy
    },
  },

  mounted() {
    this.clearFilter()
  },

  watch: {
    fields() {
      this.filterBy = this.filterPrototype()
    },
    async showFilter() {
      await this.$nextTick()
      this.afterShowFilter = this.showFilter
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
#lower-header ::v-deep th {
  position: sticky;
  top: var(--header-height) !important;
}
.hide-header ::v-deep th {
  z-index: -1 !important;
}
</style>