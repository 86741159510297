<template>
  <b-modal
    id="table-setting-modal"
    modal-class="h-100"
    dialog-class="custom-table-setting-modal"
  >
    <template #modal-title>
      Table Setting
    </template>
    <div>
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="d-flex mb-2"
      >
        <b-checkbox
          v-model="selected"
          :value="field.key"
        />
        <div>
          {{field.settingLabel}}
        </div>
      </div>
    </div>
    <template #modal-footer="{hide}">
      <b-button
        @click="submit(hide)"
      >
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    fields: Array,
    excludedFields: Array,
  },
  
  data() {
    return {
      selected: []
    }
  },

  methods: {
    submit(hide) {
      this.$emit("setExcludedFields", this.fields.filter((f) => !this.selected.includes(f.key)).map(f => f.key));
      hide();
    }
  },

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.selected = this.fields.filter((f) => !this.excludedFields.includes(f.key)).map(f => f.key)
    })
  },

  watch: {
    // selected() {
    //   console.log(this.selected)
    // }
  }
}
</script>

<style>
.custom-table-setting-modal {
  width: 230px;
  height: 100%;
  align-items: center;
  display: flex;
}
</style>